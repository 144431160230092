import React from "react"
import { body } from "./mainlayout.module.css"
import { header } from "./mainlayout.module.css"
import Header from "../components/header"

export default function MainLayout({ children }) {
    return (
        <div>
          <div className={header}>
            <Header />
          </div>
          <div className={body}>
            {children}
          </div>
        </div>
    )
}
