import React from "react"
import { Link } from "gatsby"
import { header }  from "./header.module.css"
import { image } from "./header.module.css"
import { link } from "./header.module.css"
import { title } from "./header.module.css"
import headIMG from "./header.jpg"

export default () => (
    <Link to="/" className={link}>
      <div className={header}>
        <p className={title}>Momozowy</p>
        <img className={image} src={headIMG} alt="header" />
      </div>
    </Link>
)
